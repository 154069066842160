































import { defineComponent } from "@vue/composition-api";
import moment from "moment";

export default defineComponent({
  name: "DatePicker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    attach: {
      type: Boolean,
      default: true,
    },
    onlyDateMask: {
      type: Boolean,
      default: false,
    },
    isTop: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "гггг-мм-дд",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: "datepicker-input",
    },
    year: {
      type: String,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    clearDate() {
      this.menu = false;
      this.$emit("change", "");
    },
    changeDate(value: string) {
      this.menu = false;
      this.$emit("change", value || "");
    },
  },
  computed: {
    dateMask() {
      return (date: string) => {
        if (!date) {
          return "";
        }
        return moment(date).format("DD.MM");
      };
    },
  },
  watch: {
    menu(val) {
      val &&
        this.year &&
        this.$nextTick(() => {
          (this.$refs.picker as unknown as { inputYear?: string }).inputYear = this.year;
          (this.$refs.picker as unknown as { tableDate?: string }).tableDate = `${this.year}-${
            this.value ? moment(this.value, "YYYY-MM-DD").format("MM") : this.min ? moment(this.min, "YYYY-MM-DD").format("MM") : "01"
          }`;
        });
    },
  },
});
